"use strict";
import {on, find, findAllIn, findIn, empty} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import axios from "axios";
import {resultTracking} from "@elements/tracking";

export function init() {
    onFind('.js-ajax-tab', function (element) {
        let tab = find(element.getAttribute('data-bs-target'));

        on('click', function () {
            if (element.getAttribute('data-ajax-tab-loaded') === 'false') {
                loadContent(tab, element.getAttribute('data-content-url'));
                element.setAttribute('data-ajax-tab-loaded', 'true');
            }
        }, element);
    });
}

export function loadContent(tab, url, data, method) {
    let pendingRequest;
    let target = findAllIn('.js-ajax-tab__content', tab);
    let loading = findAllIn('.js-ajax-tab__loading', tab);

    if (method === 'GET') {
        pendingRequest = axios({method: 'GET', url: url, params: data });
    } else {
        pendingRequest = axios({method: 'POST', url: url, data: data });
    }
    asyncAppend({
        target: target,
        loading: loading
    }, pendingRequest.then((x) => x.data))
        .then((result)=> {
            resultTracking(result)
        });

    return pendingRequest;
}