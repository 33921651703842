import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,addClass,on,findIn, findAllIn, hasClass} from '@elements/dom-utils';

const selectors = {
    base: '.js-header',
    transparentHeader: '.main-content--transparent-header'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let body = document.body;
        let header = findIn(selectors.base, document.body);
        let transparentHeader = findIn(selectors.transparentHeader, document.body);
        let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        window.addEventListener('scroll', function(){
            if (window.scrollY > 120){
                addClass('header--scroll', header);
                addClass('header-not-in-viewport', body);
                removeClass('header-initial-state', body);
                if(transparentHeader) {
                    removeClass('header--transparent', header);
                }
            }
            if (window.scrollY === 0){
                removeClass('header--scroll', header);
                removeClass('header-not-in-viewport', body);
                addClass('header-initial-state', body);
                if(transparentHeader) {
                    addClass('header--transparent', header);
                }
            }

            // Get the current scroll position
            let currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (lastScrollTop > currentScrollTop) {
                removeClass('header--scroll', header);
                removeClass('header-not-in-viewport', body);
            }
            lastScrollTop = currentScrollTop;
        });
    });
}
