import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, closest} from "@elements/dom-utils";

let backdrop = document.querySelectorAll('.js-backdrop')[0];
let mainNav = document.querySelectorAll('.js-side-nav')[0];
let mainNavToggler = document.querySelectorAll('.js-side-nav__main-toggle')[0];

let sideItem = document.querySelectorAll('.js-side-item');
let sideitemToggle = document.querySelectorAll('.js-side-item__toggle');

export function init() {
    /* nav toggle opens sidenav */
    onFind('.js-side-item__toggle', function (button) {
        on('click', function () {
            //close main nav, man nav toggler, sideitems and sideitem toggler
            removeClass('open', mainNav);
            removeClass('open', mainNavToggler);

            sideItem.forEach(function (item) {
                removeClass('open', item);
            });

            //get attribute data-side-item and open the item
            let item = button.getAttribute('data-side-item');
            let itemEl = document.querySelectorAll('.js-side-item[data-side-item="' + item + '"]')[0];

            if(!hasClass('open', button)) {
                sideitemToggle.forEach(function (element) {
                    removeClass('open', element);
                });
                addClass('open', button);
                addClass('open', backdrop);
                addClass('open', itemEl);
                if(item === 'search') {
                    document.querySelectorAll('.js-autocomplete')[0].focus();
                }
            } else {
                removeClass('open', button);
                removeClass('open', itemEl);
                removeClass('open', backdrop);
                if (item === 'search') {
                    document.querySelectorAll('.js-autocomplete')[0].blur();
                }
            }
        }, button);
    });


    /* close sideitems */
    onFind('.js-side-item__close', function (closebutton) {
        on('click', function () {
            let sideItem = closest('.js-side-item', closebutton);

            sideitemToggle.forEach(function (element) {
                removeClass('open', element);
            });

            removeClass('open', sideItem);
            removeClass('open', backdrop);
            removeClass('open', mainNav);
            removeClass('open', mainNavToggler);
        }, closebutton);
    });
}